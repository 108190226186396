<template>
  <div class="into1" style="">
    <!-- <van-swipe style="height: 303px;" :autoplay="3000" indicator-color="white" vertical>
      <van-swipe-item><img src="../../assets/images/banner-首页.png" alt="" width="100%" height="100%"/></van-swipe-item>
      <van-swipe-item><img src="../../assets/images/banner-自由练习.png" alt="" width="100%" height="100%"/></van-swipe-item>
    </van-swipe> -->
    <!-- class="listWrapper" -->
    <div class="banner-imag" style="width:100vw; padding:0; box-shadow: 0px 10px 15px 1px rgba(173,72,18,0.15); position:relative; overflow: hidden;">
        <div><img src="../../assets/images/banner-pay3.png" width="100%"></div>
        <!-- <div style="position:absolute; top:0px; z-index: 988;"><img src="../../assets/images/banner-pay3.png" width="100%"></div>
        <div style="position:absolute; top:50px; z-index: 989; text-align: center;"><img src="../../assets/images/shuzi.png" width="60%" style="margin-left:90px;"></div>
        <div style="position:absolute;"><img src="../../assets/images/shuzi.png" width="100%"></div> -->
    </div>
    <div style="clear:both;"></div>
    <div style=" padding:0px; margin-top:15px;">
      <!-- <van-row style="">
        <van-col span="24">
          <div style="margin:0; padding:0; box-shadow: 0px 10px 15px 1px rgba(173,72,18,0.15);"><img src="../../assets/images/banner-pay3.png" width="100%" /></div>
          <div style="clear:both;"></div>
          <h2 class="h2list" style="margin-left:0.25rem; color:#000; margin-top:20px;">{{iy}}</h2>
          <div style="position:relative; top:-15vh; left:9.5vw; color:#fff; font-weight:600;">{{iy}}</div>
        </van-col>
      </van-row> -->
      <div style="width:100vw; text-align: center; margin-top:15px; color:#666;">今日购买，有效期至{{vip_deadline}}</div>
      
      <!-- <div v-if="init_view_is_show" style="width:97vw; margin-left: 1vw; margin-top:0px; height: auto;">
        <div style="position:relative; z-index: 999; padding-left:3vw; margin-top:2.5vw;"><img src="../../assets/images/span_up.png" width="80"></div>
          <div class="column_up column4" ref="abc4" @click="mgebtn1('4', $event, resData[3]['now_price'])" style="text-align:center; position:relative; height:100px;">
            <div style="width:22vw; height:auto; float: left; padding-top:4vw; margin-left:4vw;">
              <img src="../../assets/images/libao.png" width="70">
            </div>
            <div style="width:67vw; height:100%; float: left;">
              <div style="float: left;"><p style="color:#F8A100; margin-top:0.6rem;">￥<span style="font-weight:700; font-size:32px;">{{resData[3]['now_price']}}</span></p></div>
              <div style="float: left; margin-left:2vw;">
                <p style="font-size:0.2rem; margin-top:0.5rem; font-size:18px; font-weight:600; text-align: left;">有效期<span style="font-size:24px;">{{resData[3]['price_name']}}</span>天</p>
                <p style="text-align: left; font-size: 15px;">
                  <a style="margin-left:1vw; text-decoration:line-through; color:#777;">原价￥{{resData[3]['original_price']}}</a>
                  <a style="margin-left:3vw;">每位新用户仅一次机会</a>
                </p>
              </div>
            </div>
            <p style="text-align:right; position: absolute; bottom: 0; width:100%; line-height:5px; padding-top:5px; visibility:hidden;" ref="abc4s"><img src="../../assets/images/jiaobiao.png" width="23"></p>
          </div>
      </div> -->

      <!-- <div style="clear:both;"></div> -->
      
      <ul style="margin-top:2vw; margin-left:0.1rem; overflow:hidden; height:auto;">
        <!-- <div class="grid">
          <div class="column column1" ref="abc1" @click="mgebtn1('1', $event, resData[0]['now_price'])" style="text-align:center; position:relative;">
            <p style="margin-top:25px;"><img src="../../assets/images/zuanshi.png" width="32"></p>
            <p style="font-size:0.2rem; font-size:18px; font-weight:600;">有效期<span style="font-size:25px;">{{resData[0]['price_name']}}</span>天</p>
            <p style="color:#F8A100;">￥<span style="font-weight:700; font-size:32px;">{{resData[0]['now_price']}}</span></p>
            <p style="text-decoration:line-through; color:#777;">原价￥{{resData[0]['original_price']}}</p>
            <p style="text-align:right; position: absolute; bottom: 0; width:100%; line-height:5px; padding-top:5px;" ref="abc1s"><img src="../../assets/images/jiaobiao.png" width="23"></p>
          </div>

          <div class="column column2" ref="abc2" @click="mgebtn1('2', $event, resData[1]['now_price'])" style="text-align:center; position:relative;">
            <p style="margin-top:25px;"><img src="../../assets/images/zuanshi.png" width="32"></p>
            <p style="font-size:0.2rem; font-size:18px; font-weight:600;">有效期<span style="font-size:25px;">{{resData[1]['price_name']}}</span>天</p>
            <p style="color:#F8A100;">￥<span style="font-weight:700; font-size:32px;">{{resData[1]['now_price']}}</span></p>
            <p style="">{{resData[1]['original_price']}}</p>
            <p style="text-align:right; position: absolute; bottom: 0; width:100%; line-height:5px; padding-top:5px; visibility:hidden;" ref="abc2s"><img src="../../assets/images/jiaobiao.png" width="23"></p>
          </div>

          <div class="column column3" ref="abc3" @click="mgebtn1('3', $event, resData[2]['now_price'])" style="text-align:center; position:relative;">
            <p style="margin-top:25px;"><img src="../../assets/images/zuanshi.png" width="32"></p>
            <p style="font-size:0.2rem; font-size:18px; font-weight:600;">有效期<span style="font-size:25px;">{{resData[2]['price_name']}}</span>天</p>
            <p style="color:#F8A100;">￥<span style="font-weight:700; font-size:32px;">{{resData[2]['now_price']}}</span></p>
            <p style="">{{resData[2]['original_price']}}</p>
            <p style="text-align:right; position: absolute; bottom: 0; width:100%; line-height:5px; padding-top:5px; visibility:hidden;" ref="abc3s"><img src="../../assets/images/jiaobiao.png" width="23"></p>
          </div>

        </div> -->

        <div v-if="showLoadPrice">
          <ul>
            <div>
              <ul>
                <li class="price_check_one" ref="pri1" @click="mgebtn2('1', $event, resData[0]['now_price'])">
                  <p><img :src="srcRef1" width="100%" ref="srcRef" /></p>
                </li>
                <li class="price_check_one one" ref="pri2" @click="mgebtn2('2', $event, resData[1]['now_price'])">
                  <p><img :src="srcRef2" width="100%" ref="srcRef" /></p>
                </li>
                <li class="price_check_one" ref="pri3" @click="mgebtn2('3', $event, resData[2]['now_price'])">
                  <p><img :src="srcRef3" width="100%" ref="srcRef" /></p>
                </li>
              </ul>
            </div>

            <li class="price_check_all" ref="pri4" @click="mgebtn2('4', $event, resData[3]['now_price'])" style="color:#E7732D;">
              <div style="padding-left:0vw; margin-left:0px; margin-top:0px; position:absolute; float:left;">
                <img src="../../assets/images/span.png" width="65">
              </div>
              <div style="position:absolute; float:right; right:19vw; margin-top:2.1vh; width:5vw; height:46px; line-height:46px; font-size:0.6rem; font-weight:700;">
                {{vipAllPrice}}
              </div>
              <p><img :src="srcRef4" width="100%" ref="srcRef" /></p>
            </li>

          </ul>
        </div>
        <!-- <p>
          <van-radio-group v-model="checkedNewPrice">
            <ul class="ul_price" v-for="(item, index) in resData" :key="index">
              <li> 
                <van-radio :name="index" checked-color="green" @click="getRadioVal(item.now_price)">VIP - {{item["price_name"]}}<br>
                  <span>原价￥{{item["original_price"]}}</span>
                  <span style="color:crimson; text-decoration:none;">  限时优惠{{item["now_price"]}}元</span>
                </van-radio>
              </li>
            </ul>
          </van-radio-group>
        </p> -->
        <!-- <li>支付明细：<span style="color:red;">技能家VIP</span></li>
        <li>支付说明：<span style="color:red;">支付成功后自动激活</span></li> -->
      </ul>
      <!-- <div style="position:relative;  padding-left:3.5vw; margin-top:-150px;"><img src="../../assets/images/span.png" width="65"></div> -->
      <van-row style="padding:0px;">
        <!-- <van-col span="24" style="padding:0.25rem 0.25rem;">
          <van-col span="6" style="margin-left:0.2rem;">支付方式：</van-col>
          <van-col v-if="checkPlatForm == 1 || checkPlatForm == 2" span="17" style="">
            <van-col span="3" style="margin-left:0.2rem;"><img src="../../assets/images/wachat.png" width="35re" />
            </van-col>
            <van-col span="11" style="margin-left:0.2rem; line-height:0.6rem; text-align:left;">支付宝支付</van-col>
            <van-col span="2" style="margin-left:1rem; margin-top:0.1rem;">
              <van-radio-group v-model="checked">
                <van-radio name="1"></van-radio>
              </van-radio-group>
            </van-col>
          </van-col>
        </van-col>
        <van-col span="24" style="padding:0.25rem 0.25rem;">
          <van-col span="6" style="margin-left:0.2rem;"></van-col>
          <van-col v-if="checkPlatForm == 0 || checkPlatForm == 2" span="17" style="">
            <van-col span="3" style="margin-left:0.2rem;"><img src="../../assets/images/alipay.png" width="35re">
            </van-col>
            <van-col span="11" style="margin-left:0.2rem; line-height:0.6rem; text-align:left;">微信支付</van-col>
            <van-col span="2" style="margin-left:1rem; margin-top:0.1rem;">
              <van-radio-group v-model="checked">
                <van-radio name="2"></van-radio>
              </van-radio-group>
            </van-col>
          </van-col>
        </van-col> -->
        <div style="margin:12px;">
            <div v-if="checkPlatForm == 1 || checkPlatForm == 2" style="width:45vw; height:auto; float:left; text-align: left; margin-left:0.5vw;">
              <img src="../../assets/images/btn-pay-aliy.png" width="165" @click="newPayForm('Aliy')" />
            </div>
            <div v-if="checkPlatForm == 0 || checkPlatForm == 2" style="width:45vw; height:auto; float:right; text-align: right; margin-right:1.5vw;">
              <img src="../../assets/images/btn-pay-wchat.png" width="165" @click="newPayForm('Wechat')" />
            </div>
        </div>
        <div style="height:10px; clear:both;"></div>

        <div style="margin:12px; margin-top:10px;">
          <img src="../../assets/images/qysm.png" width="100%" />
        </div>
      </van-row>
    </div>
    <!-- <div>
      <van-col span="24" style="padding-left:5vw; padding-top: 2.5vw;">
        <img src="../../assets/images/minx.png" width="94%" />
      </van-col>
    </div> -->
    <!-- <div style="clear:both; width:100%; margin-top:1rem;"></div> -->

    <div class="pay_siss" style="padding-left: 4vw; padding-bottom: 5vw; padding-top: 2.5vw;">
      <p style="font-weight:700;">支付说明:</p>
      <p>1.支付成功后自动激活；</p>
      <p>2.有效期自支付成功之日起计算，过期失效；</p>
      <p>3.该商品为虚拟商品，不支持退款，请知悉。</p>
    </div>

    <div class="pay_sisse" style="text-align:center; padding-bottom: 5vw; padding-top: 2.5vw; font-size: 0.25rem; line-height:0.25rem; color:#999;">
      <p>上海帛钥智能科技有限公司</p>
      <p style="font-size:0.1rem;">沪ICP备18047614号-1</p>
      <p style="font-size:0.1rem;">Copyright © 2018</p>
    </div>

    <!-- <div style="text-align: center; margin:0 auto; overflow: hidden; width: 100%;">
      <div style="width:80%;  margin:0 auto;">
        <van-button round color="#3F7EEF" size="large" style="" @click="clkZfb">支付</van-button>
      </div>
    </div> -->

    <!-- <van-tag type="primary">{{jgName}}</van-tag> -->

    <!-- <van-divider content-position="left" dashed>归属:{{jgName}}</van-divider>
    <div class="bg002">
      <ul>
        <li v-if="checkPlatForm == 0 || checkPlatForm == 2"><a class="button" @click="clkWach">微信支付</a></li>
        <li v-if="checkPlatForm == 1 || checkPlatForm == 2"><a class="button" @click="clkZfb">支付宝支付</a></li>
      </ul>
      <p><img src="../../assets/logo.png" width="40%" /></p>
    </div> -->

    <van-dialog v-model="isShow" show-cancel-button :beforeClose="beforeClose" style="left:50vw;">
      <template v-slot:title>
        <h3 style="display: block; padding:0.5rem 0.3rem; color:#909399; word-spacing: 1rem;">请先绑定您的个人信息</h3>
      </template>
      <van-field v-model="userName" label="姓名" placeholder="请输入您真实的姓名" />
      <van-field v-model="userPhone" label="手机号" placeholder="请输入手机号" type="number" maxlength="11" />
      <template>
        <span
          style="display: block; padding:0.5rem 0.3rem; color:#909399; word-spacing: 1rem;">支付成功后，您的授权信息将由短信发送给您，请注意查收</span>
      </template>

    </van-dialog>

  </div>
</template>

<script>
import api from "../../api/ztb";
import { Dialog } from 'vant';
// import { Grid, GridItem } from 'vant';
import { ref } from 'vue'
import { createApp } from 'vue';
import axios from 'axios'


// import { showDialog } from 'vant';

export default {
  // name: "帛钥智能 - 渠道支付",
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },

  data() {
    return {
      high: ['rrt', 'uui'], //高风险区数据
      mid: [], //低风险区域
      dialogVisible: false,
      isShow: false,
      userName: '学员',
      userPhone: '',
      visible: false,
      jgName: "",
      checkPlatForm: 2, //媒介检测，0微信，1，支付宝，2浏览器
      orderId: "1dsf2343234324324",
      checked: 1,
      checkedNewPrice: 2,
      yytr: 0,
      price: 98,
      timeLimit: 30,
      userId: 0,
      userInstionId:0,
      souUserStatus: false,
      ttr: [],
      iy: this.$route.query.jg,
      resData:[],
      vipAllPrice: 58,
      mgeColor: true,
      pwdColor: false,
      mgeColor1: true,
      mgeColor2: false,
      mgeColor3: false,
      mgeColor4: false,
      price_status: 1, //选中价格状态
      price_type: 3, //选中的是几档价格
      vip_deadline: null,
      init_view_is_show:false,
      showLoadPrice:false,
      priceName:"VIP畅学卡",
      // srcRef1: require("../../assets/images/vip-llk1.png"),
      // srcRef2: require("../../assets/images/vip-sck1.png"),
      // srcRef3: require("../../assets/images/vip-mnk1.png"),
      srcRef1: require("../../assets/images/llk.png"),
      srcRef2: require("../../assets/images/sck.png"),
      srcRef3: require("../../assets/images/mnk.png"),
      srcRef4: require("../../assets/images/vip-cxk1-active.png"),
    };
  },

  setup() {
    const checked = ref('1');
    return { checked };
  },

  // computed: {

  // },

  created() {
    this.GetNewPrice(this.iy)
    this.divIsShow()
    this.jgNames()
    this.IsWeixinOrAlipay()
    this.iniDateTime()
  },

  mounted () {
  // alert(this.yytr)
  },

  methods: {
    divIsShow(){
      if(this.$route.query.init_view_is_show == 1){
        this.init_view_is_show = true;
      }else{
        this.init_view_is_show = false;
      }
    },

    iniDateTime(){
      let myDate1 = new Date();
      this.vip_deadline = this.dateAddDays(myDate1.toLocaleDateString(), 3)
    },
    //v1.1.5版支付，从app端接收电话号码即可无感支付
    newPayForm(e){
      // console.log("支付方式", e)
      // console.log("价格选中状态(0未选中价格，1已选中价格)：", this.price_status)
      // console.log("价格级别(一个月，三个月，一年)：", this.price_type)
      // console.log("截至日期：", this.vip_deadline)
      // console.log(this.resData)
      // return false
      if(this.price_status == 0){
        Dialog({ message: '请先选定套餐^_^' });
      }else{
        // alert(this.resData[this.price_type]['now_price'])
        // alert(this.resData[this.price_type]['price_name'])

        
        if(this.price_type == 3){
          this.price = this.vipAllPrice;
          this.timeLimit = this.resData[this.price_type]['price_name']
        }else{
          this.price = this.resData[this.price_type]['now_price']
          this.timeLimit = this.resData[this.price_type]['price_name']
        }
        // alert(this.price)
        
        
        // return;
        if(e == 'Aliy'){
          this.checked = 1
        }
        if(e == 'Wechat'){
          this.checked = 2
        }
        let testPhone = localStorage.getItem('get_app_url_user_phone'); //this.$route.query.userPhone
        if(testPhone == null || testPhone==''){
          testPhone = this.$route.query.phones
        }
        var data = new URLSearchParams();
        data.append('username', this.userName);   // 添加数据
        data.append('userphone', testPhone);   // 添加数据this.$route.query.jg
        data.append('institution_code', this.$route.query.jg);
        data.append('timeLimit', this.timeLimit);
        // console.log("---------->>>>>>>>>>>")
        // console.log(data)
        // console.log(this.timeLimit)
        this.submitFormUser(data)
      }
    },

    mgebtn2(msg, event, now_price){
      let myDate = new Date();
      if(msg==1){
        this.srcRef1 = require("../../assets/images/llk1-active.png")
          this.price_status = 1
          this.price_type = 0
          this.priceName = this.resData[0]['price_title']
          this.srcRef2 = require("../../assets/images/sck.png")
          this.srcRef3 = require("../../assets/images/mnk.png")
          this.srcRef4 = require("../../assets/images/vip-cxk1.png")
          this.$refs.pri4.style.color = "#43506C";
          this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), this.resData[0]['price_name'])

          // this.$refs.pri1.style.background = "#FBF5E5";
          // this.$refs.pri1.style.border = "1px solid #E7A438";
          // this.price_status = 1
          // this.price_type = 0
          // this.priceName = this.resData[0]['price_title']
          // this.$refs.pri2.style.background = "#FAFAFA", this.$refs.pri2.style.border = "1px solid #D0D0D0";
          // this.$refs.pri3.style.background = "#FAFAFA", this.$refs.pri3.style.border = "1px solid #D0D0D0";
          // this.$refs.pri4.style.background = "#FAFAFA", this.$refs.pri4.style.border = "1px solid #D0D0D0";
          // this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), this.resData[0]['price_name'])
      }
      if(msg==2){
          this.srcRef2 = require("../../assets/images/sck1-active.png")
          this.price_status = 1
          this.price_type = 1
          this.priceName = this.resData[1]['price_title']
          this.srcRef1 = require("../../assets/images/llk.png")
          this.srcRef3 = require("../../assets/images/mnk.png")
          this.srcRef4 = require("../../assets/images/vip-cxk1.png")
          this.$refs.pri4.style.color = "#43506C";
          this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), this.resData[1]['price_name'])

          // this.$refs.pri2.style.background = "#FBF5E5";
          // this.$refs.pri2.style.border = "1px solid #E7A438";
          // this.price_status = 1
          // this.price_type = 1
          // this.priceName = this.resData[1]['price_title']
          // this.$refs.pri1.style.background = "#FAFAFA", this.$refs.pri1.style.border = "1px solid #D0D0D0";
          // this.$refs.pri3.style.background = "#FAFAFA", this.$refs.pri3.style.border = "1px solid #D0D0D0";
          // this.$refs.pri4.style.background = "#FAFAFA", this.$refs.pri4.style.border = "1px solid #D0D0D0";
          // this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), this.resData[1]['price_name'])
      }
      if(msg==3){
          this.srcRef3 = require("../../assets/images/mnk1-active.png")
          this.price_status = 1
          this.price_type = 2
          this.priceName = this.resData[2]['price_title']
          this.srcRef1 = require("../../assets/images/llk.png")
          this.srcRef2 = require("../../assets/images/sck.png")
          this.srcRef4 = require("../../assets/images/vip-cxk1.png")
          this.$refs.pri4.style.color = "#43506C";
          this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), this.resData[2]['price_name'])

          // this.$refs.pri3.style.background = "#FBF5E5";
          // this.$refs.pri3.style.border = "1px solid #E7A438";
          // this.price_status = 1
          // this.price_type = 2
          // this.priceName = this.resData[2]['price_title']
          // this.$refs.pri1.style.background = "#FAFAFA", this.$refs.pri1.style.border = "1px solid #D0D0D0";
          // this.$refs.pri2.style.background = "#FAFAFA", this.$refs.pri2.style.border = "1px solid #D0D0D0";
          // this.$refs.pri4.style.background = "#FAFAFA", this.$refs.pri4.style.border = "1px solid #D0D0D0";
          // this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), this.resData[2]['price_name'])
      }
      if(msg==4){
          this.srcRef4 = require("../../assets/images/vip-cxk1-active.png")
          this.price_status = 1
          this.price_type = 3
          this.priceName = this.resData[3]['price_title']
          this.srcRef1 = require("../../assets/images/llk.png")
          this.srcRef2 = require("../../assets/images/sck.png")
          this.srcRef3 = require("../../assets/images/mnk.png")
          this.$refs.pri4.style.color = "#E7732D";
          this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), this.resData[3]['price_name'])

          // this.$refs.pri4.style.background = "#FBF5E5";
          // this.$refs.pri4.style.border = "1px solid #E7A438";
          // this.price_status = 1
          // this.price_type = 3
          // this.priceName = this.resData[3]['price_title']
          // this.$refs.pri1.style.background = "#FAFAFA", this.$refs.pri1.style.border = "1px solid #D0D0D0";
          // this.$refs.pri2.style.background = "#FAFAFA", this.$refs.pri2.style.border = "1px solid #D0D0D0";
          // this.$refs.pri3.style.background = "#FAFAFA", this.$refs.pri3.style.border = "1px solid #D0D0D0";
          // this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), this.resData[3]['price_name'])
      }
    },

    mgebtn1 (msg, event, now_price) {
      // alert(msg)
      // alert(event)
      // alert(now_price)
      let myDate = new Date();
      if(msg==1){
        if (this.mgeColor1 == true) {
          this.$refs.abc1.style.background = "#F9F7F7";
          this.$refs.abc1.style.border = "1px solid #E8E6E7";
          this.$refs.abc1s.style.visibility = "hidden";
          this.mgeColor1 = false
          this.price_status = 0
        }else{
          this.$refs.abc1.style.background = "#FDF5E3";
          this.$refs.abc1.style.border = "#F8A100 solid 1px";
          this.$refs.abc1s.style.visibility = "visible";
          this.mgeColor1 = true
          // this.$refs.bfb1.style.visibility = "visible"
          //处理其它状态
          this.$refs.abc2.style.background = "#F9F7F7";
          this.$refs.abc2.style.border = "#E8E6E7 solid 1px";
          this.$refs.abc2s.style.visibility = "hidden";
          this.mgeColor2 = false;
          this.$refs.abc3.style.background = "#F9F7F7";
          this.$refs.abc3.style.border = "#E8E6E7 solid 1px";
          this.$refs.abc3s.style.visibility = "hidden";
          this.mgeColor3 = false;
          if(this.init_view_is_show == true){
            this.$refs.abc4.style.background = "#F9F7F7";
            this.$refs.abc4.style.border = "#E8E6E7 solid 1px";
            this.$refs.abc4s.style.visibility = "hidden";
          }
          this.mgeColor4 = false;
          this.price_type = 0
          this.price_status = 1
          this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), this.resData[0]['price_name'])
        }
      }
      if(msg==2){
        if (this.mgeColor2 == true) {
          this.$refs.abc2.style.background = "#F9F7F7";
          this.$refs.abc2.style.border = "#E8E6E7 solid 1px";
          this.$refs.abc2s.style.visibility = "hidden";
          this.mgeColor2 = false
          this.price_status = 0
          console.log("---->>>>XXX")
        }else{
          this.$refs.abc2.style.background = "#FDF5E3";
          this.$refs.abc2.style.border = "#F8A100 solid 1px";
          this.$refs.abc2s.style.visibility = "visible";
          this.mgeColor2 = true
          // this.$refs.bfb1.style.visibility = "visible"
          //处理其它状态
          this.$refs.abc1.style.background = "#F9F7F7";
          this.$refs.abc1.style.border = "#E8E6E7 solid 1px";
          this.$refs.abc1s.style.visibility = "hidden";
          this.mgeColor1 = false;
          this.$refs.abc3.style.background = "#F9F7F7";
          this.$refs.abc3.style.border = "#E8E6E7 solid 1px";
          this.$refs.abc3s.style.visibility = "hidden";
          this.mgeColor3 = false;
          if(this.init_view_is_show == true){
            this.$refs.abc4.style.background = "#F9F7F7";
            this.$refs.abc4.style.border = "#E8E6E7 solid 1px";
            this.$refs.abc4s.style.visibility = "hidden";
          }
          // this.$refs.abc4.style.background = "#F9F7F7";
          // this.$refs.abc4.style.border = "#E8E6E7 solid 1px";
          // this.$refs.abc4s.style.visibility = "hidden";
          this.mgeColor4 = false;
          this.price_type = 1;
          console.log("---->>>>√√√")
          console.log(this.price_type)
          this.price_status = 1
          this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), this.resData[1]['price_name'])
        }
      }
      if(msg==3){
        if (this.mgeColor3 == true) {
          this.$refs.abc3.style.background = "#F9F7F7";
          this.$refs.abc3.style.border = "#E8E6E7 solid 1px";
          this.$refs.abc3s.style.visibility = "hidden";
          this.mgeColor3 = false
          this.price_status = 0
        }else{
          this.$refs.abc3.style.background = "#FDF5E3";
          this.$refs.abc3.style.border = "#F8A100 solid 1px";
          this.$refs.abc3s.style.visibility = "visible";
          this.mgeColor3 = true
          // this.$refs.bfb1.style.visibility = "visible"
          //处理其它状态
          this.$refs.abc1.style.background = "#F9F7F7";
          this.$refs.abc1.style.border = "#E8E6E7 solid 1px";
          this.$refs.abc1s.style.visibility = "hidden";
          this.mgeColor1 = false;
          this.$refs.abc2.style.background = "#F9F7F7";
          this.$refs.abc2.style.border = "#E8E6E7 solid 1px";
          this.$refs.abc2s.style.visibility = "hidden";
          this.mgeColor2 = false;
          if(this.init_view_is_show == true){
            this.$refs.abc4.style.background = "#F9F7F7";
            this.$refs.abc4.style.border = "#E8E6E7 solid 1px";
            this.$refs.abc4s.style.visibility = "hidden";
          }
          // this.$refs.abc4.style.background = "#F9F7F7";
          // this.$refs.abc4.style.border = "#E8E6E7 solid 1px";
          // this.$refs.abc4s.style.visibility = "hidden";
          this.mgeColor4 = false;
          this.price_type = 2
          this.price_status = 1
          this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), this.resData[2]['price_name'])
        }
      }
      if(msg==4){
        if (this.mgeColor4 == true) {
          this.$refs.abc4.style.background = "#F9F7F7";
          this.$refs.abc4.style.border = "#E8E6E7 solid 1px";
          this.$refs.abc4s.style.visibility = "hidden";
          // this.$refs.bfb1.style.visibility = "visible"
          // this.$refs.bfb2.style.visibility = "hidden"
          this.mgeColor4 = false
          this.price_status = 0
        }else{
          if(this.init_view_is_show == true){
            this.$refs.abc4.style.background = "#FDF5E3";
            this.$refs.abc4.style.border = "#F8A100 solid 1px";
            this.$refs.abc4s.style.visibility = "visible";
          }
          // this.$refs.abc4.style.background = "#FDF5E3";
          // this.$refs.abc4.style.border = "#F8A100 solid 1px";
          // this.$refs.abc4s.style.visibility = "visible";

          // this.$refs.bfb1.style.visibility = "hidden"
          // this.$refs.bfb2.style.visibility = "visible"
          this.mgeColor4 = true;

          // this.$refs.abc4_2s.style.text-align = right;
          // this.$refs.abc4_2s.style.position = "absolute";
          // this.$refs.abc4_2s.style.bottom = 0;
          // this.$refs.abc4_2s.style.width = "100%";
          // this.$refs.abc4.style.visibility = "hidden";
          //处理其它状态
          this.$refs.abc1.style.background = "#F9F7F7";
          this.$refs.abc1.style.border = "#E8E6E7 solid 1px";
          this.$refs.abc1s.style.visibility = "hidden";
          this.mgeColor1 = false;
          this.$refs.abc2.style.background = "#F9F7F7";
          this.$refs.abc2.style.border = "#E8E6E7 solid 1px";
          this.$refs.abc2s.style.visibility = "hidden";
          this.mgeColor2 = false;
          this.$refs.abc3.style.background = "#F9F7F7";
          this.$refs.abc3.style.border = "#E8E6E7 solid 1px";
          this.$refs.abc3s.style.visibility = "hidden";
          this.mgeColor3 = false;
          this.price_type = 3
          this.price_status = 1
          this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), this.resData[3]['price_name'])
        }
      }
      this.getRadioVal(now_price)
      // if (this.mgeColor1) {
      //   this.$refs.abc1.style.background = "#F9F7F7";
      //   this.$refs.abc1.style.border = "#E8E6E7 solide 1px";
      //   this.$refs.abc1s.style.visibility = "hidden";
      //   this.mgeColor1 = false
      // } else {
      //   this.$refs.abc1.style.background = "#FDF5E3";
      //   this.$refs.abc1.style.border = "#F8A100 solide 1px";
      //   this.$refs.abc1s.style.visibility = "visible";
      //   this.mgeColor1 = true
      //   //处理第二和第三
      //   this.$refs.abc2.style.background = "#F9F7F7";
      //   this.$refs.abc2.style.border = "#E8E6E7 solide 1px";
      //   this.$refs.abc2s.style.visibility = "hidden";
      //   this.mgeColor2 = false;
      // }
    },

    // mgebtn2 (msg, event) {
    //   console.log("-----------ww----------")
    //   console.log(msg);
    //   // alert("111222" + this.mgeColor2)
    //   if (this.mgeColor2 == false) {
    //     this.$refs.abc2.style.background = "#FDF5E3";
    //     this.$refs.abc2.style.border = "#F8A100 solide 1px";
    //     this.$refs.abc2s.style.visibility = "visible";
    //     this.mgeColor2 = true;
    //   } else {
    //     this.$refs.abc2.style.background = "#F9F7F7";
    //     this.$refs.abc2.style.border = "#E8E6E7 solide 1px";
    //     this.$refs.abc2s.style.visibility = "hidden";
    //     this.mgeColor2 = false;
    //     //处理第一和第三
    //     this.$refs.abc1.style.background = "#F9F7F7";
    //     this.$refs.abc1.style.border = "#E8E6E7 solide 1px";
    //     this.$refs.abc1s.style.visibility = "hidden";
    //     this.mgeColor1 = false;
    //   }
    // },

    //v1.1.5版新支付阶梯
    GetNewPrice(query){
      // console.log("归属机构：", query)
      let quesa = axios.get('/json/jgPrice.json').then(res => {     // 获取public下的buildmenu.json文件数据
          // console.log('-------------读取的机构价格json数据------------')
          // console.log(res)
          let myDate = new Date();
          for(var i=0; i < res.data.length; i++){
            if(res.data[i]['mechanism_name'] == query){
              // console.log(res.data[i]['list'])
              this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), res.data[i]['list'][0]['price_name'])
              this.resData = res.data[i]['list']
              this.showLoadPrice = true
            }
          }
          let datas = {
            "code": 2000,
            "data":{
                "data":res.data,
                "limit": 10,
                "page": 1,
                "total": 10
            },
            "msg": "success"
          }
          return datas
      })
      return quesa
    },

    dateAddDays(dateStr,dayCount) {
      var tempDate=new Date(dateStr.replace(/-/g,"/"));//把日期字符串转换成日期格式
      var resultDate=new Date((tempDate/1000+(86400*dayCount))*1000);//增加n天后的日期
      var resultDateStr=resultDate.getFullYear()+"-"+(resultDate.getMonth()+1)+"-"+(resultDate.getDate());//将日期转化为字符串格式
      return resultDateStr;
12  },

    getRadioVal(value){
      this.yytr = value
      // alert(value)
    },

    clkZfb() {
      // 绑定用户
      this.clkWach()
    },

    IsWeixinOrAlipay() {
      var ua = window.navigator.userAgent.toLowerCase();
      //判断是不是微信
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        console.log("********************** WeiXIN模式")
        this.checkPlatForm = 0;
        return "WeiXIN";
      }
      //判断是不是支付宝
      if (ua.match(/AlipayClient/i) == 'alipayclient') {
        console.log("********************** Alipay模式")
        this.checkPlatForm = 1;
        return "Al\ipay";
      }
      //哪个都不是
      // console.log("********************** 普通浏览器打开模式")
      this.checkPlatForm = 2;
      return "false";
    },

    jgNames() {
      // http://localhost:8081/#/into1?jg=0002
      if (this.$route.query.jg == '0001') {
        this.jgName = "职通宝机构"
      } else if (this.$route.query.jg == '0002') {
        this.jgName = "恒山机构"
      } else if (this.$route.query.jg == '0003') {
        this.jgName = "华山机构"
      } else if (this.$route.query.jg == '0004') {
        this.jgName = "嵩山机构"
      } else {
        this.jgName = "其他机构..."
      }
      // alert(this.$route.query.phones)
      let params = {
        phones:this.$route.query.phones
      }
      api.postUserPhoneAndPrice(params).then((res)=>{
        if (res.status == 200) {
          this.vipAllPrice = res.data.Eesult.vipAllPrice
        }
      })
    },

    clkWach() {
      this.isShow = !this.isShow
      // if(this.checked == 2){
      //   Dialog.alert({ //微信
      //           // message: this.checked + "微信"
      //           message: "暂未开通，请选择支付宝进行支付！"
      //         }).then(() => {
      //           // on close
      //         })
      // }else{
      //   this.isShow = !this.isShow
      // }
      
    },



    //v1.1.3版支付，需要输入电话号码和用户才能支付
    beforeClose(action, done) {
      console.log("***********ddd***********")
      // console.log(this.$route.query)
      // console.log(this.$route.params)
      if (action === 'confirm') {
        console.log("action = ", action)
        if (!this.userName || !this.userPhone) {
          this.$toast("请输入姓名及电话号码")
          done(false) //不关闭弹框
        } else {
          // let jg_key = ''
          // if(this.$route.query.jg){
          //   jg_key = this.$route.query.jg
          // }else{
          //   jg_key = '技能家机构'
          // }
          var data = new URLSearchParams();
          data.append('username', this.userName);   // 添加数据
          data.append('userphone', this.userPhone);   // 添加数据this.$route.query.jg
          data.append('institution_code', this.$route.query.jg);
          this.submitFormUser(data)
          //setTimeout(done, 1000)
        }

      } else if (action === 'cancel') {
        // console.log("action = ", action)
        // this.$toast("取消绑定！")
        done(true) //关闭
      }
    },



    //发送绑定, 如果用户不存在则新增用户
    submitFormUser(params) {
      api.postCovInfo(params).then((res) => {
        // console.log(res);
        if (res.status == 200) {
          // this.$toast("绑定成功！")
          // console.log("绑定成功！");
          // console.log(res.data);
          this.userId = res.data.userId
          this.userInstionId = res.data.userInstionId
          this.souUserStatus = true
          // if(this.price == 180.00){
          //   this.price = 0.01
          // }
          // alert(this.price,typeof(this.price))
          this.ttr = [
            {price: this.price},
            {userId: this.userId},
            {jg: this.userInstionId},
            {timeLimit: this.timeLimit},
            {priceName: this.priceName},
          ]

          //平台检测，浏览器，微信，支付宝
          // 普通浏览器环境下
          if (this.checkPlatForm == 2) {
            // console.log(this.ttr)
            // return;
            if (this.checked == 1) { //支付宝
              this.$router.push({ path: 'AliPay', query: { price: this.ttr }});
              // Dialog.alert({
              //   message: this.checked + "支付宝"
              //   }).then(() => {
              // // on close
              // })
            } else {
              this.$router.push({path: 'WxPay', query: { price: this.ttr }});



              // Dialog.alert({ //微信
              //   // message: this.checked + "微信"
              //   message: "暂未开通，请选择支付宝进行支付！"
              // }).then(() => {
              //   // on close
              // })
              // return
              // window.location.href('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8120e608eacdd78f&redirect_uri=http://sakhr8081.cpolar.cn&response_type=code&scope=snsapi_userinfo&state=type%3Dquan%2Curl%3Dhttp%3A%2F%2Fmm.dianping.com%2Fweixin%2Faccount%2Fhome')
            }

          }
          // 支付宝环境下
          if (this.checkPlatForm == 1) {
            if (this.checked == 1) {
              this.$router.push({ path: 'AliPay', query: { price: this.ttr } });
              // Dialog.alert({
              //   message: this.checked + "支付宝"
              //   }).then(() => {
              // // on close
              // })
            }
          }
          // 微信环境下
          if (this.checkPlatForm == 0) {
            // this.$router.push({ path: 'Home', query: { price: this.ttr } }); //不适用这一条

              // Dialog.alert({ //微信
              //   // message: this.checked + "微信"
              //   message: "微信内支付暂未开通,建议使用浏览器扫码微信支付，或者选择支付宝进行支付！"
              // }).then(() => {
              //   // on close
              // })
              //return
            //先创建用户支付订单
            this.$router.push({ path: 'WxPay2', query: { price: this.ttr } });
          }
          // this.sqfk()//微信授权
        } else {
          this.$toast("绑定失败！")
          console.log("绑定失败！");
        }
      });
    },

    // 判断是否中文
    isChinese(temp) {
      var re = /[^\u4E00-\u9FA5]/;
      if (re.test(temp)) return false;
      return true;
    },

  }
};
</script>

<style lang='less' scoped>

.pay_siss p{
  line-height: 0.6rem;
  color:#666666;
}
.grid {
        display: flex;
        z-index: 777;
        flex-wrap: wrap;
        /* box-sizing: border-box; */
        width: 95vw;
        /* height: 100vh; */
        // background: goldenrod;
        margin-top:-5vw;
        
}
.column_up {
  width: 93vw;
  margin-left: 2vw;
  border-radius: 6px;
  margin-top:-3vw;
}
.grid .column {
    width: 29vw;
    height: 40vw;
    margin-left: 2vw;
    border-radius: 6px;
}
.column1{
  background:#FDF5E3;
  border:#F8A100 solid 1px;
}
.column2{
  background:#F9F7F7;
  border:#E8E6E7 solid 1px;
}
.column3{
  background:#F9F7F7;
  border:#E8E6E7 solid 1px;
}
.column4{
  // background:#F9F7F7;
  // border:#E8E6E7 solid 1px;
  // border-radius: 9px;
  background:#F9F7F7;
  border:#E8E6E7 solid 1px;
}
.bfb_img{
  // position: absolute;
  // margin-left: 0;
  width: 100%;
  height:100%;
}

.bfb_img-check{
  // text-align:right; position: absolute; bottom: 0; width:100%; line-height:5px; padding-top:5px; visibility:hidden;
  // text-align:right;
  // position: absolute;
  // margin-left: 0;
  // bottom: 0;
  width:100%;
  visibility:visible;
}
.Color{
    // background-color: #F9F7F7;
    background-color: green;
    border: 1px solid black;
}
.Color1{
  background-color: red;
  border: 1px solid black;
}
.ul_price li span{
  text-decoration:line-through;
  color:#777;
}
/deep/.van-radio__label{
  line-height:40px;
}
.listWrapper{
  background:red;
  height: 24vh; 
  line-height:43vh; 
  color:#000;
}
.h2list{
  display: inline;

}

.into1 {
  font-size: 0.3rem;
}

.into1 ul li {
  padding: 0.25rem;
}

.banner-imag img{
  display:block;
  height:100%;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: auto;
  overflow: hidden;
  text-align: center;
  // background-color: #39a9ed;
}

.into1 {
  background: #F5F8FA;
}

.bg {
  height: 3.6rem;
  background: url("../../assets/images/area-1.png") no-repeat;
  background-size: cover;
  padding-top: 5rem;
}

.bg001 {
  height: 5rem;
  background: url("../../assets/images/area-1.png") no-repeat;
  background-size: cover;
  padding: 0.25rem 0.25rem;
  color: #D43030;
}

.bg002 {
  background: #fff;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  // padding: 0.2rem;
  border-radius: 0.2rem;
  min-height: 8rem;
  margin-bottom: 0.2rem;

  p {
    margin-top: 1rem;
    text-align: center;
    color: #777;
  }
}

.bg002 ul li {
  margin: 0.8rem 0.15rem;
  text-align: center;
}

.bg002 .button {
  background-color: #4D88F2;
  /* Green */
  border-radius: 0.15rem;
  border: none;
  color: white;
  padding: 0.15rem 0.32rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.25rem;
  width: 45vw;
  height: 10vw;
  line-height: 10vw;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bg002 .button:hover {
  background-color: #4e8ebf;
  /* Green */
  border-radius: 0.15rem;
  border: none;
  color: white;
  padding: 0.15rem 0.32rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 45vw;
  height: 10vw;
  line-height: 10vw;
}

.content {
  background: #ECD773;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  padding: 0.2rem;
  border-radius: 0.2rem;
  min-height: 10rem;
  margin-bottom: 0.2rem;

  .th {
    margin-bottom: 0.3rem;
    font-size: 0.34rem;
  }

  .th:before {
    display: inline-block;
    width: 0.1rem;
    height: 0.3rem;
    margin-right: 0.1rem;
    background-color: #4169e2;
    content: "";
    vertical-align: middle;
  }

  .title {
    font-size: 0.3rem;
    margin-bottom: 0.3rem;

    img {
      width: 0.4rem;
      vertical-align: top;
    }
  }

  .list {
    li {
      margin-bottom: 0.2rem;
      color: #333;
      background: #f7f7f7;
      padding: 0.1rem;
      border-radius: 0.1rem;
      letter-spacing: 0.01rem;
      line-height: 0.5rem;
    }
  }
}

.into1 ul li{
  padding:0px;
}
.price_check_all {
    display:block;
    width: 97vw;
    padding:0;
    margin-top:0rem;
    background:#FAFAFA;
    // margin:0.18rem;
    line-height:0.5rem;
    overflow: hidden;
    border-radius: 5px;
    // border:1px solid #D0D0D0;
  }
  .price_check_one {
    display:block;
    float:left;
    width:31vw;
  }
  .one {
    margin-left:2.2vw;
    margin-right:2.2vw;
  }
  .price_check_all h4{
    width: 100%;
  }
  .price_check_all span{
    display:block;
    float:right;
    text-align: right;
    margin-right:0px;
  }
</style>